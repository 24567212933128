.adp-combine-picker-footer-extra {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.adp-combine-picker-input>input {
  color: #4D5869 !important;
}
._39EsVdHlEDwxK1vOb4SpMa {
  background-color: #F6F8F9;
  box-sizing: border-box;
  padding: 0 12px;
  display: flex;
}
._39EsVdHlEDwxK1vOb4SpMa ._1-7wHhe9__vC8KEBAtKnWI {
  flex: 1;
}
._39EsVdHlEDwxK1vOb4SpMa ._1-7wHhe9__vC8KEBAtKnWI span {
  padding: 2px 8px;
  color: #4D5869;
  cursor: pointer;
}
._39EsVdHlEDwxK1vOb4SpMa ._1-7wHhe9__vC8KEBAtKnWI span:hover {
  background-color: #fff;
  border-radius: 4px;
}
