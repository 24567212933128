._3vwSemxWSDNjEmJ1bW4Lrz {
  font-size: 18px;
  margin-right: 3px;
  font-weight: 200;
  margin-top: -2px;
}
._3p1v-ar7rOQQUW7fKKAG1D {
  display: flex;
  align-items: center;
  padding: 0 13px;
}
