._1Cw7AR4rdQYY_aAvANwQKv {
  padding: 0 5px;
}
._1Cw7AR4rdQYY_aAvANwQKv ._2H8Ql_x9Jeb5ZX2-LB-U1U {
  line-height: 30px;
  height: 30px;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  padding-left: 30px;
}
._1Cw7AR4rdQYY_aAvANwQKv ._2hmyNM881gGeUMXHEpIc_3 {
  position: absolute;
  top: -1px;
  left: 5px;
}
._1Cw7AR4rdQYY_aAvANwQKv ._2TJNZuVi4B-biaDEWaXTLv {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._1Cw7AR4rdQYY_aAvANwQKv ._2H8Ql_x9Jeb5ZX2-LB-U1U:hover,
._1Cw7AR4rdQYY_aAvANwQKv .p7jDRrpl1CYhk2acyVuFz {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.05);
}
._3NxwjvkuoUekwc28GzeLoH {
  padding: 8px;
  width: 230px;
  box-sizing: border-box;
}
.SXNNq1HbThMhbjJDuXP-Y {
  color: #868E9F;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 0 8px 8px;
}
._1mmpr5m_pJ7e25yUKJWv6a {
  color: #030303;
  font-size: 16px;
  font-weight: 500;
  padding-left: 8px;
  padding-bottom: 8px;
}
.gCDuPpm_ijkMVI9gxDReo {
  display: flex;
  padding-left: 8px;
  padding-bottom: 8px;
}
.gCDuPpm_ijkMVI9gxDReo label {
  white-space: nowrap;
}
._1nb0zuFsk4v2G-LB_mU7nk {
  cursor: pointer;
  width: 20px;
  padding-left: 3px;
}
._1nb0zuFsk4v2G-LB_mU7nk:hover {
  color: #296BEF;
}
._18LrVidu5ipzcTQ5GKBXyh {
  border-top: #EEEFF3 solid 1px;
  max-height: 150px;
  overflow: auto;
  margin-top: 2px;
  padding-top: 8px;
}
._3c6JOowrt6nA91AoK9nfwl {
  line-height: 16px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}
._3c6JOowrt6nA91AoK9nfwl:hover {
  background-color: #EEF4FE;
  color: #296BEF;
}
