._24yRuDONDbhdeXz_UzQclj {
  position: relative;
  display: flex;
  align-items: center;
}
._24yRuDONDbhdeXz_UzQclj ._1Q8mywOKSWgP3SVI8nSL75 {
  top: 0;
  left: 0;
  z-index: 2;
  position: absolute;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC4wMjA5IDIuOTQ5ODJDMTEuOTczNSA0LjkwMjQ0IDExLjk3MzUgOC4wNjgyNiAxMC4wMjA5IDEwLjAyMDlDOC4wNjgyNiAxMS45NzM1IDQuOTAyNDQgMTEuOTczNSAyLjk0OTgyIDEwLjAyMDlDMC45OTcxOTYgOC4wNjgyNiAwLjk5NzE5NiA0LjkwMjQ0IDIuOTQ5ODIgMi45NDk4MkM0LjkwMjQ0IDAuOTk3MTk2IDguMDY4MjYgMC45OTcxOTYgMTAuMDIwOSAyLjk0OTgyWk0xMS4wNjcgMTAuMzU5OUMxMy4wNjUxIDguMDAyNTUgMTIuOTUyMSA0LjQ2NjggMTAuNzI4IDIuMjQyNzFDOC4zODQ4NSAtMC4xMDA0MzUgNC41ODU4NiAtMC4xMDA0MzUgMi4yNDI3MSAyLjI0MjcxQy0wLjEwMDQzNSA0LjU4NTg2IC0wLjEwMDQzNSA4LjM4NDg1IDIuMjQyNzEgMTAuNzI4QzQuNDY2NzkgMTIuOTUyMSA4LjAwMjUyIDEzLjA2NTEgMTAuMzU5OSAxMS4wNjdMMTIuODQ5MyAxMy41NTY0QzEzLjA0NDYgMTMuNzUxNyAxMy4zNjExIDEzLjc1MTcgMTMuNTU2NCAxMy41NTY0QzEzLjc1MTcgMTMuMzYxMiAxMy43NTE3IDEzLjA0NDYgMTMuNTU2NCAxMi44NDkzTDExLjA2NyAxMC4zNTk5WiIgZmlsbD0iIzg2OEU5RiIvPgo8L3N2Zz4K);
  background-repeat: no-repeat;
  background-position-y: 9px;
  background-position-x: 9px;
  width: 30px;
  height: 30px;
}
._24yRuDONDbhdeXz_UzQclj input {
  padding-left: 23px;
}
._24yRuDONDbhdeXz_UzQclj .adp-combine-input-inner {
  align-items: center;
}
._24yRuDONDbhdeXz_UzQclj .adp-combine-input-suffix {
  display: flex;
  align-items: flex-end;
  line-height: 1.5715;
}
._24yRuDONDbhdeXz_UzQclj .adp-combine-input-close svg {
  width: 14px !important;
  height: 14px !important;
}
