._3RwzmUa2xxkxAxUTbKzkB- {
  padding-bottom: 16px;
}
._3RwzmUa2xxkxAxUTbKzkB- ._1fl6WXkvb5d4n1JnrPRJnO {
  width: 80px;
  line-height: 32px;
  vertical-align: top;
  display: inline-block;
  color: #4D5869;
}
._3RwzmUa2xxkxAxUTbKzkB- ._1fl6WXkvb5d4n1JnrPRJnO._1_7XelgXiUXJoOHCyDsKdH {
  color: #D0D2D6;
}
._3RwzmUa2xxkxAxUTbKzkB- ._1fl6WXkvb5d4n1JnrPRJnO ._2BF8gmOm067Evn9rkGnsbR {
  padding: 0 5px;
  color: #FD433B;
}
._3RwzmUa2xxkxAxUTbKzkB- ._1RaDBJeF6C3eU51SPWaJsU {
  display: inline-block;
  vertical-align: top;
}
._1H4kcWRkNDPWmlSMJv7zEZ {
  display: inline-block;
  margin-right: 24px;
}
._1H4kcWRkNDPWmlSMJv7zEZ ._1fl6WXkvb5d4n1JnrPRJnO {
  width: auto;
  margin-right: 16px;
}
