._1gyFiUgTkTwD2hs3Y56XTp {
  overflow: hidden;
  text-overflow: ellipsis;
}
._1_qOqxPCbu-zHNX1ZrDrLD {
  white-space: nowrap;
}
._3eok-WqvYUrWVJtrBMnJak {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.-hpcSCLB5U8dGzDVZZNEc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.fRt3EQIMKD44zPJ_QiQFj {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.CHGceVxTfSjwlzZ81lkbe {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
.pidIpOuQJSnOWfhMU1uVV {
  word-break: break-all;
  word-wrap: break-word;
}
.tea-bubble--reverse .tea-bubble__inner {
  background-color: rgba(0, 0, 0, 0.9) !important;
}
.tea-bubble--reverse.tea-bubble--bottom .tea-bubble__inner:after {
  border-top-color: rgba(0, 0, 0, 0.9) !important;
}
.tea-bubble__inner {
  padding: 8px !important;
}
