._2UrP0vyxJWMAztft276LAn {
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;
  color: #4D5869;
  font-weight: 400;
}
._2ql3Gr7O2N8RNNyKq6kjN8 {
  clear: both;
}
._2gaiCMcCSyuxg-6oySz9M3 {
  display: block;
  float: left;
}
._3dLkX1YrhJsTQCBpvy2mYx {
  display: block;
  float: right;
}
._1QajkfPxpIhLdd75awZoEo {
  position: absolute;
  top: 8px;
  right: 0;
  z-index: 100;
}
