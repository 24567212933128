.Znk_rDC_oJH0ubobnUaYl {
  padding: 0 0 16px 0;
  min-width: 800px;
  position: relative;
  height: 100%;
  font-family: 'PingFang SC';
}
.Znk_rDC_oJH0ubobnUaYl ._19R2AOe9RNdYgonqZ-ccAO {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.Znk_rDC_oJH0ubobnUaYl ._3ccIyUvl9WpSxwqNMJJojC {
  padding-top: 15px;
}
._3UjZC7HjagzDXulmw4CQ1 {
  background-color: #fff;
  border-radius: 4px;
  padding: 0 0 16px 0;
  min-width: 1140px;
  position: relative;
  height: 100%;
  font-family: 'PingFang SC';
}
._3UjZC7HjagzDXulmw4CQ1 ._19R2AOe9RNdYgonqZ-ccAO {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  overflow: auto;
}
._3UjZC7HjagzDXulmw4CQ1 ._3ccIyUvl9WpSxwqNMJJojC {
  padding-top: 15px;
}
._2Db4zZ0CKyWNe-LQWRh2ad {
  height: 100%;
  padding-right: 24px;
  min-width: 900px;
  position: relative;
  font-family: 'PingFang SC';
}
._2Db4zZ0CKyWNe-LQWRh2ad ._3ccIyUvl9WpSxwqNMJJojC {
  position: relative;
  margin-bottom: 5px;
}
._2Db4zZ0CKyWNe-LQWRh2ad ._3ccIyUvl9WpSxwqNMJJojC::after {
  content: '';
  position: absolute;
  height: 1px;
  left: 0;
  bottom: 0;
  right: 24px;
  background-color: #E6E8ED;
}
._2Db4zZ0CKyWNe-LQWRh2ad ._19R2AOe9RNdYgonqZ-ccAO {
  background-color: #fff;
  border-radius: 4px;
  padding: 16px 24px;
  height: 100%;
  overflow: auto;
}
._3ccIyUvl9WpSxwqNMJJojC {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #030303;
  padding-bottom: 16px;
}
._3hPboah6iQkfdqijiJZ9Ou {
  display: flex;
  justify-content: flex-end;
}
._1v8a7vaqbSFOGbhhNOhsiK {
  flex: 1;
}
._3dYhMjE7aPo-aqfYUvZrJx {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  top: 200px;
  z-index: 100000;
}
