._3EltEFco_FuKDJJF_-WJYZ {
  position: relative;
}
._3EltEFco_FuKDJJF_-WJYZ .DJllCBEhDL_FOEb7SlSgp {
  position: absolute;
  right: 8px;
  top: 6px;
  color: #D0D2D6;
  z-index: 100;
}
.bsdl9di0WPnqu_w4cPBmH {
  display: flex;
  flex-direction: column;
}
.bsdl9di0WPnqu_w4cPBmH ._208QAhCl-NFM7_XQ-TANbj {
  margin-top: 2px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #FD433B;
}
._2Ax1iW8i9m1ofvekVOPveZ .adp-combine-input-inner,
._2Ax1iW8i9m1ofvekVOPveZ .adp-combine-text-area {
  border: 1px solid #FD433B !important;
}
