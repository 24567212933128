._1guN7-jfEsE3s5mGuIfYN4 {
  padding: 0 5px;
}
._1guN7-jfEsE3s5mGuIfYN4 .adp-combine-popover-trigger {
  display: inline;
}
._1guN7-jfEsE3s5mGuIfYN4 ._2MizjjrubLxR6AM7m5HVpZ {
  cursor: pointer;
  display: flex;
  position: relative;
  padding-left: 30px;
  height: 30px;
}
._1guN7-jfEsE3s5mGuIfYN4 ._1pIHPknbfp2jHpOzPVpdUt {
  position: absolute;
  top: -1px;
  left: 5px;
}
._1guN7-jfEsE3s5mGuIfYN4 ._1SKxhmOsin-aHyryYu1vTN {
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
._1guN7-jfEsE3s5mGuIfYN4 ._3TY_v_1Mda15j18U9okFRC {
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._1guN7-jfEsE3s5mGuIfYN4 ._2MizjjrubLxR6AM7m5HVpZ:hover,
._1guN7-jfEsE3s5mGuIfYN4 .NLToEIkr_o4qZ5RA0lUs8 {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.05);
}
.e3bV_hqYnFtyDAEnGpV4s {
  padding: 8px;
  width: 230px;
  box-sizing: border-box;
}
.yaUVVoZAeqkOs4W42o7z3 {
  color: #868E9F;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 0 8px 8px;
}
._2-iN6a_6gtbv5H2PcGA9aH {
  color: #030303;
  font-size: 16px;
  font-weight: 500;
  padding-left: 8px;
  padding-bottom: 8px;
}
._1b93TpkhmT9OzjNWxcesgF {
  display: flex;
  padding-left: 8px;
}
._2XuAZUdbiqQh9IK_0jMD9F {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vTdejVieYDGzcx6pygg71 {
  cursor: pointer;
  width: 16px;
  padding-left: 3px;
}
.vTdejVieYDGzcx6pygg71:hover {
  color: #296BEF;
}
._1wBon1IP9P7WJFQiV1pK-8 {
  border-top: #EEEFF3 solid 1px;
  max-height: 130px;
  overflow: auto;
  margin-top: 10px;
}
._2ihXnfmuayIOgMTCgmA4FO {
  line-height: 16px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 40px;
}
._2ihXnfmuayIOgMTCgmA4FO div {
  position: absolute;
  color: #296BEF;
  display: none;
  right: 10px;
  top: 7px;
}
._2ihXnfmuayIOgMTCgmA4FO:hover {
  background-color: #EEF4FE;
  color: #296BEF;
}
._2ihXnfmuayIOgMTCgmA4FO:hover div {
  display: block;
}
._1qDQKUb3Y_aUXxZymkyoz2 {
  padding-top: 10px;
  border-top: #EEEFF3 solid 1px;
  padding-left: 8px;
}
