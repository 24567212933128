._3ZB6BzhEZYeIdchfDWyETR {
  position: relative;
  margin-top: 24px;
}
._3ZB6BzhEZYeIdchfDWyETR .adp-combine-pagination {
  text-align: right;
}
.hd8IIVgP8UDf_uacPDnrs {
  position: absolute;
  left: 0;
  color: rgba(0, 0, 0, 0.6);
}
._3KOA4BYXJnUmw7fS3uzWCg .adp-combine-table-thead > tr > th {
  padding: 12px;
}
.DohGiY9h9kOtzmTZ60ZMf .adp-combine-table {
  border: 1px solid #E6E8ED;
  overflow: auto;
}
.DohGiY9h9kOtzmTZ60ZMf .adp-combine-table-thead > tr > th {
  border-bottom: 1px solid #f0f0f0;
}
.DohGiY9h9kOtzmTZ60ZMf .adp-combine-table-content::-webkit-scrollbar {
  height: 6px;
}
.DohGiY9h9kOtzmTZ60ZMf .adp-combine-table-content::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.4);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.DohGiY9h9kOtzmTZ60ZMf .adp-combine-table-content::-webkit-scrollbar-trackr {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.DohGiY9h9kOtzmTZ60ZMf .adp-combine-table::-webkit-scrollbar {
  height: 6px;
}
.DohGiY9h9kOtzmTZ60ZMf .adp-combine-table::-webkit-scrollbar-thumb {
  background-color: rgba(128, 128, 128, 0.4);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.DohGiY9h9kOtzmTZ60ZMf .adp-combine-table::-webkit-scrollbar-trackr {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
}
.DohGiY9h9kOtzmTZ60ZMf th {
  white-space: nowrap;
}
